<template>
  <v-list-item class="pa-0 pb-2">
    <!-- Icon -->
    <v-list-item-icon v-if="item.parentId" class="pa-0 ma-0">
      <v-icon color="black" class="mr-3">
        subdirectory_arrow_right
      </v-icon>
    </v-list-item-icon>

    <!-- Avatar -->
    <v-list-item-avatar v-else max-width="44" rounded="25" color="#919090" class="mt-0">
      <v-img v-if="user && user.picture && user.picture.publicUrl" :src="user.picture.publicUrl" max-width="44" />
      <v-icon v-else color="white" v-text="'person_outline'" />
    </v-list-item-avatar>

    <v-list-item-content class="pa-0 align-self-start">
      <!-- Content -->
      <v-list-item-content class="pa-0">
        <v-textarea v-if="item.isFromStaff && isEditing" v-model="content" hide-details outlined class="pa-0 ma-0" auto-grow rows="1" row-height="2" />
        <span v-else v-text="item.content" />
      </v-list-item-content>

      <!-- Actions -->
      <v-list-item-action-text class="pt-2">
        <span v-if="!isEditing" class="text--secondary pr-2"> {{ userName }} · {{ date }} · {{ time }}</span>
        <!-- Reply -->
        <v-chip v-if="!item.isFromStaff && item.accepted" class="mr-2" color="white" @click="handleCreateEmptyReply">
          <v-icon left v-text="'reply'" />
          <span class="text--secondary" v-text=" $t('comment.reply')" />
        </v-chip>
        <!-- Edit -->
        <v-chip v-if="item.isFromStaff && !isEditing" class="mr-2" color="white" @click="isEditing = true">
          <v-icon left v-text="'edit'" />
          <span class="text--secondary" v-text=" $t('comment.edit')" />
        </v-chip>
        <!-- Save -->
        <v-chip v-if="item.isFromStaff && isEditing" class="mr-2" color="white" :disabled="!content" @click="item.id ? handleUpdate() : handleCreateReply()">
          <v-icon left v-text="'save'" />
          <span class="text--secondary" v-text=" $t('common.save')" />
        </v-chip>
        <!-- Cancel -->
        <v-chip v-if="isEditing" class="mr-2" color="white" @click="handleCancel">
          <v-icon left v-text="'cancel'" />
          <span class="text--secondary" v-text=" $t('common.cancel')" />
        </v-chip>
        <!-- Accept -->
        <v-chip v-if="!item.isFromStaff && !item.accepted " class="mr-2" color="white" @click="handleAccept">
          <v-icon left v-text="'check'" />
          <span class="text--secondary" v-text=" $t('comment.accept')" />
        </v-chip>
        <!-- Delete -->
        <v-chip v-if="item.id && !isEditing" class="mr-2" color="white" @click="handleDelete">
          <v-icon left v-text="'delete_outline'" />
          <span class="text--secondary" v-text=" $t('comment.delete')" />
        </v-chip>
      </v-list-item-action-text>

      <!-- Children -->
      <v-list v-if="item.children" class="pa-0 mt-2">
        <comment-list-item v-if="newReply" :item="newReply" @delete-new-reply="newReply = null" />
        <comment-list-item v-for="child in item.children" :key="child.id" :item="child" />
      </v-list>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { DateTime } from 'luxon'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CommentListItem',
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      user: null,
      content: '',
      newReply: null,
      isEditing: false,
    }
  },
  computed: {
    ...mapGetters({ projectData: 'project/data' }),
    ...mapGetters({ userData: 'user/data' }),
    userName({ user }) {
      return user?.firstName
    },
    date({ item, formatDate }) {
      return formatDate(item.updatedAt)
    },
    time({ item, formatTime }) {
      return formatTime(item.updatedAt)
    },
  },
  async created() {
    this.user = await this.read(this.item.userId)
    this.content = this.item?.content || ''
    if (!this.content) this.isEditing = true
  },
  methods: {
    ...mapActions('comment', ['create', 'update', 'delete', 'accept']),
    ...mapActions('user', ['read']),
    handleCreateEmptyReply() {
      const parentId = this.item.id
      this.newReply = { parentId, userId: this.userData.id, isFromStaff: true, accepted: true }
    },
    async handleCreateReply() {
      this.$store.commit('loader/show')
      const { organizationId, projectId, activityId } = this.$route.params
      const data = (({ children, ...rest }) => ({ ...rest, content: this.content }))(this.item)
      this.$emit('delete-new-reply')
      await this.create({ organizationId, projectId, activityId, data })
      this.$store.commit('loader/hide')
    },
    async handleDelete() {
      const { organizationId, projectId, activityId } = this.$route.params
      this.$confirm(this.$t('common.confirm')).then(res => (res && this.delete({ organizationId, projectId, activityId, commentId: this.item.id })))
    },
    async handleUpdate() {
      this.$store.commit('loader/show')
      const { organizationId, projectId, activityId } = this.$route.params
      const data = (({ children, ...rest }) => ({ ...rest, content: this.content }))(this.item)
      await this.update({ organizationId, projectId, activityId, commentId: this.item.id, data })
      this.isEditing = false
      this.$store.commit('loader/hide')
    },
    handleAccept() {
      const { organizationId, projectId, activityId } = this.$route.params
      this.accept({ organizationId, projectId, activityId, commentId: this.item.id })
    },
    handleCancel() {
      this.isEditing = false
      if (!this.item.content) this.$emit('delete-new-reply')
      this.content = this.item.content
    },
    // Helpers
    formatDate(date) {
      if (!date) return ''
      return DateTime.fromSeconds(date.seconds, {
        zone: this.projectData.timezone,
      }).toLocaleString()
    },
    formatTime(date) {
      if (!date) return ''
      return DateTime.fromSeconds(date.seconds, {
        zone: this.projectData.timezone,
      }).toLocaleString(DateTime.TIME_24_SIMPLE)
    },
  },
}
</script>
